body {
	font-family: 'Source Sans Pro', Arial, sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	background: #fff;
	padding-top: 60px;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
	font-family: "Exo 2", Arial, sans-serif;
}

h1.entry-title {
	margin-bottom: 20px;
}

.first {
	margin-top: 0;
}

h2:after {
  content: "";
  display: block;
  margin-top: 5px;
  width: 40px;
  height: 3px;
  line-height: 0;
}

h2.entry-title {
	margin-top: 0;
}

a, a:hover, a:focus, a:active {
	-webkit-transition: all 150ms ease-in-out;
	-moz-transition: all 150ms ease-in-out;
	-ms-transition: all 150ms ease-in-out;
	-o-transition: all 150ms ease-in-out;
	transition: all 150ms ease-in-out;
	text-decoration: none;
	outline: none;
}

a:hover {
	color: #09519d;
}

.main p {
	margin-bottom: 24px;
	line-height: 1.5em;
	color: #696969;
}

.main strong {
	color: #333;
}

hr {
	border-color: #e2e2e2;
}

hr.big {
	margin-top: 60px;
	margin-bottom: 60px;
}

/* Content */
.wrap {
	padding-top: 45px;
	padding-bottom: 90px;
}

.main *:first-child {
	margin-top: 0;
}

/* Responsive Facebook Like Box and iframe */
iframe, object, embed {
	max-width: 100%;
}

.iframe-border {
	padding: 4px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
}

#fb-root {
    display: none;
}

.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
    width: 100% !important;
}

/* Icomoon Font */
@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?-mdbett");
    src: url("../fonts/icomoon.eot?#iefix-mdbett") format("embedded-opentype"), url("../fonts/icomoon.woff?-mdbett") format("woff"), url("../fonts/icomoon.ttf?-mdbett") format("truetype"), url("../fonts/icomoon.svg?-mdbett#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

.im {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.im-home:before {
    content: "\e601";
}

.im-paintbrush:before {
    content: "\e60e";
}

.im-loading:before {
    content: "\e71c";
}
	
.im-ruler:before {
    content: "\e788";
}

.im-license:before {
  content: "\e65b";
}