/* Home */
.front-header {
	color: #fff;
	background-color: #193c9d;
	background-image: url("../images/header-bg.jpg");
}

.front-header-inner {
	padding: 60px 0 30px;
	background-color: #09519d;
	background-color: rgba(9,81,157,0.9);
}

.front-header-text {
	margin-bottom: 60px;
	position: relative;
	text-align: center;
	z-index: 20;
}

.front-header-text img {
	margin: 0 auto 16px auto;
	display: block;
	max-width: 100%;
	height: auto;
}

.front-header-text h1:after {
	margin: 10px auto 0 auto;
}

h1:after, h2:after {
	background-color: #f18e00;
}

h1:after {
	content: "";
	display: block;
	margin-top: 10px;
	width: 50px;
	height: 4px;
	line-height: 0;
}

@media (max-width: @grid-float-breakpoint) {
    #tjanster .col-md-3 {
        margin-bottom: 15px;
    }
}

.service-item .service-title {
    position: relative;
    height: 0;
    padding-bottom: 70%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.service-item .service-title>div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.service-item .service-title>div>div {
    display: table;
    width: 100%;
    height: 100%;
}

.service-item .service-title>div>div>div {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
}

.service-item .service-icon {
    display: inline-block;
    margin-bottom: -10px;
    font-size: 70px;
	color: #f18e00;
}

.service-item .service-title h3 {
    margin: 0;
    padding: 0 15px;
    font-size: 1.5em;
    text-transform: uppercase;
}

.service-item .service-title a {
	color: #fff;
}

.service-item .service-description>div {
    padding: 10px;
}

.service-item .service-description p {
    margin: 12px 0 0 0;
}

.service-item .service-description .btn {
    background: none;
	border: 3px solid white;
}

.service-item .service-description .btn:hover {
    color: #fff;
	border-color: #09519d;
}

.service-item .service-description p:first-child {
    margin-top: 0;
}

.service-item {
    margin: 0;
}

.service-item .service-inner {
    margin: 5px;
    padding-bottom: 70%;
    height: 0;
    overflow: hidden;
	position: relative;
}

.service-item .service-title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
	
.service-item .service-description {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 101%;
    height: 101%;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.service-item:hover .service-description {
    opacity: 1;
    filter: alpha(opacity=100);
}

.service-item:hover .service-description {
    background: #f18e00;
}

.service-item .service-description>div {
    display: table;
    padding: 0;
    width: 100%;
    height: 100%;
}

.service-item .service-description>div>div {
    display: table-cell;
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    border: 0;
}

.service-item .service-description p {
    margin: 10px 0 0 0;
	color: #fff;
}

.service-item .service-description p:first-child {
    margin-top: 0;
}

.service-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin: -10px;
}

.service-list>li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.service-list>li:before {
    content: none;
    display: inline;
    position: inherit;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background: none;
}

.service-list:before, .service-list:after {
    display: table;
    clear: both;
    height: 0;
    content: '';
}
	
.service-list {
    margin: -5px;
    margin-bottom: 24px;
}

.page-title-inner {
    position: relative;
    margin: -10px -40px 0 -40px;
	background: #fff;
}

.page-title-inner:before, .page-title-inner:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: -15px;
    width: 15px;
    height: 100px;
}

@media (min-width: @grid-float-breakpoint) {
.page-title-inner:before {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), to(rgba(0,0,0,0.15)));
	background-image: -webkit-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: -moz-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: -o-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: linear-gradient(to right, rgba(255,255,255,0),rgba(0,0,0,0.15));
}

.page-title-inner:after {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.15)), to(rgba(255,255,255,0)));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: -o-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: linear-gradient(to right, rgba(0,0,0,0.15),rgba(255,255,255,0));
}
}

.page-title-inner:after {
    left: auto;
    right: -15px;
}

.page-title-inner2:before, .page-title-inner2:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: -15px;
    width: 15px;
    height: 100px;
}

.page-title-inner2:before, .page-title-inner2:after {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#fff));
	background-image: -webkit-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: -moz-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: -o-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: linear-gradient(to bottom, rgba(255,255,255,0),#ffffff);
	z-index: 10;
}

.page-title-inner2:after {
    left: auto;
    right: -15px;
}

.page-title-bar {
    padding: 0 40px;
}

.page-title-bar-inner {
    padding: 24px 0;
    border-bottom: 1px solid #e2e2e2;
}

.page-title-bar-inner:before, .page-title-bar-inner:after {
    display: table;
    clear: both;
    height: 0;
    content: '';
}
	
.page-title-bar-left {
    list-style-type: none;
    margin: 0;
    padding: 0;
    float: left;
}

.page-title-bar-left>li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.page-title-bar-left>li:before {
    content: none;
    display: inline;
    position: inherit;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background: none;
}

.page-title-bar-left>li {
    display: inline-block;
    margin-right: 40px;
    padding: 14px 0;
    font-size: 18px;
}

.page-title-bar-left>li>i:first-child {
    position: relative;
    top: 3px;
    margin-right: 10px;
    font-size: 24px;
	color: #f18e00;
}

.page-title-bar-left a {
    color: #333;
}

.page-title-bar-right {
    float: right;
}

.page-title-bar hr:before, .page-title-bar hr:after {
    display: table;
    clear: both;
    height: 0;
    content: '';
}

#tjanster {
	margin: 60px 0;
}

#byggservice {
	padding: 120px 0 0 0;
	border-top: 1px solid #e2e2e2;
	border-bottom: 1px solid #e2e2e2;
}

.byggservice-image img {
	margin-top: -70px;
}

#certificate {
	padding: 30px 0;
	background: #f2f2f2;
	border-bottom: 1px solid #e2e2e2;
}

.certificate-images {
	float: left;
	margin-right: 10px;
}

.certificate-block {
	position: relative;
	padding-left: 70px;
	float: left;
}

.certificate-block .feature-icon {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 50px;
	color: #f18e00;
}

.certificate-block h3 {
	margin: 0;
}

.certificate-download .block {
	float: left;
}

.certificate-download img {
	margin-right: 10px;
}

.certificate-download h3 {
	margin: 0;
}

.certificate-download ul li {
	float: left;
}

#news {
	padding: 60px 0;
	border-top: 1px solid #e2e2e2;
}

.news-inner {
	padding: 24px 20px;
	background-color: #f2f2f2;
}

.news-cropped {
	position: relative;
	height: 225px;
	overflow: hidden;
}

.news-cropped:after {
	background-image: -webkit-gradient(linear, left bottom, left top, from(#f2f2f2), to(rgba(241,241,241,0)));
	background-image: -webkit-linear-gradient(bottom, #f2f2f2, rgba(241,241,241,0));
	background-image: -moz-linear-gradient(bottom, #f2f2f2, rgba(241,241,241,0));
	background-image: -o-linear-gradient(bottom, #f2f2f2, rgba(241,241,241,0));
	background-image: linear-gradient(to top, #f2f2f2,rgba(241,241,241,0));
}

.news-cropped:after {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
}

.news-meta {
	display: block;
	margin: 0 0 10px 0;
	font-size: 0.875em;
	color: #696969;
}

.updated {
	text-transform: capitalize;
}

.news-meta a {
	color: #696969;
	border-bottom: 1px solid #f18e00;
}

.news-item h3 {
	margin: 0 0 20px 0;
}

.news-item h3 a {
	color: #333;
}

.news-item h3 a:hover {
	color: #f18e00;
}

.reference-items {
	margin-left: -5px;
	margin-right: -5px;
}

.reference-item {
	padding: 0 5px 10px 5px;
}

.reference-inner {
	padding: 20px 15px;
	text-align: center;
}

p.reference-subtitle {
	margin: 0;
}

.reference-item h3 {
	margin-bottom: 0;
}

.contactpersons {
	margin-left: -5px;
	margin-right: -5px;
}

.contactperson-item {
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}

.contactperson-inner {
	text-align: center;
	padding: 15px;
}

.contactperson-inner h3 {
	margin: 0;
}

.contactperson-inner ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.grey-block {
	background: #f9f9f9;
	padding: 45px;	
}

hr.big {
	margin: 50px 0;
}