.content-info {
	color: #fff;
	background-color: #000;
}

.footer-inner {
	background-color: #000;
	background-color: rgba(0,0,0,0.92);
	padding: 60px 0;
}

.footer-inner h3:after {
	content: '';
	display: block;
	background-color: #f18e00;
	margin-top: 8px;
	width: 30px;
	height: 2px;
}

.footer-inner .widget p {
	margin-bottom: 24px;
	line-height: 1.5em;
	color: #aaa;
}

.footer-inner .widget strong {
	color: #ddd;
}

.footer-inner ul {
	padding: 0;
	list-style: none;
}

.footer-inner ul a {
	color: #aaa;
}

.footer-inner ul a:hover {
	color: #f18e00;
}

.footer-inner hr {
	border-top-color: #222;
	border-top-color: rgba(255,255,255,0.1);
}

.footer-inner .copyright {
	color: #aaa;
}