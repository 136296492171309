.gform_wrapper .gfield_required {
	color: red !important;
}

.gform_wrapper input[type=text], .gform_wrapper input[type=url], .gform_wrapper input[type=email], .gform_wrapper input[type=tel], .gform_wrapper input[type=number], .gform_wrapper input[type=password], .gform_wrapper textarea, .gfield_select {
	border: 1px solid #d2d2d2;
	padding: .313em;
}

.gform_footer .gform_button {
	color: #fff;
	background: #f18e00;
	border: 3px solid #f18e00;
	padding: 10px 18px 9px;
	font-family: "Exo 2",Arial,sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	-webkit-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	border-radius: 4px;
	line-height: 1.42857143;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	white-space: nowrap;
}

.gform_footer .gform_button:hover {
	background: #fff;
	color: #09519d;
	border-color: #09519d;
}

.gform_wrapper div.validation_error {
	font-weight: 400;
	border: 0;
	padding: 0;
}