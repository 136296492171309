.btn {
	padding: 10px 18px 9px 18px;
	font-family: "Exo 2",Arial,sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	border: 2px solid transparent;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	 transition: all 300ms ease-in-out;
}

.btn-primary {
	background: #f18e00;
	border: 3px solid #f18e00;
}

.btn-primary:hover {
	background: #fff;
	color: #09519d;
	border-color: #09519d;
}

.btn-medium {
	padding: 15px 25px;
}