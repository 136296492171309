.entry-content img {
		margin-bottom: 15px;
}

.blog article.post {
		margin-bottom: 40px;
}

.archive article.post, .search article.post {
		margin-bottom: 20px;
}

.blog article.post:last-child, .archive article.post:last-child, .search article.post:last-child {
		margin-bottom: 0;
}

.archive h2.entry-title, .search h2.entry-title {
	font-size: 1.6em;
}

/*
.archive article, .search article {
	margin-bottom: 15px;
}

.archive .article-inner, .search .article-inner {
	background: #f2f2f2;
}

.archive .article-thumbnail, .search .article-thumbnail {
	float: left;
	width: 25%;
}

.archive .article-meta, .search .article-meta {
	padding: 15px 0 0 15px;
}

.archive .article-content, .search .article-content {
	float: right;
	width: 75%;
	padding: 15px;
}

.archive .article-content h2, .search .article-content h2 {
	margin: 0;
}

.archive .article-content h2 a, .search .article-content h2 a {
	color: #333;
}

.archive .article-content h2 a:hover, .search .article-content h2 a:hover {
	color: #09519d;
}
*/

#comments {
	background: #f9f9f9;
	padding: 45px;
}

#comments .comment-author .fn {
	margin-left: 5px;
	text-transform: capitalize;
}

#comments .comment-metadata {
	font-style: italic;
	font-size: 0.85em;
	text-transform: capitalize;
	margin-top: 5px;
}

#comments .reply, #comments .comment-edit-link {
	display: none;
}

#comments .comment-list {
	border-bottom: 1px dotted #bbb;
	margin-bottom: 22px;
}

.gallery-row {
	margin-left: -5px;
	margin-right: -5px;
}

.gallery-row .col-sm-4, .gallery-row .col-lg-4 {
	padding-left: 5px;
	padding-right: 5px;
}

.gallery-row a.thumbnail {
	margin-bottom: 10px;
}

.gallery-row img {
	margin: 0;
}

.posts-navigation {
	margin-top: 20px;
}

.posts-navigation .nav-previous {
	float: left;
}
	
.posts-navigation .nav-next {
	float: right;
}

.posts-navigation a {
	background: #f18e00;
	color: #fff;
	padding: 10px 18px 9px;
	font-family: "Exo 2",Arial,sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	border: 3px solid #f18e00;
	-webkit-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	white-space: nowrap;
}

.posts-navigation a:hover {
	background: #fff;
	color: #09519d;
	border-color: #09519d;
}