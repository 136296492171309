.navbar-brand {
	height: auto;
	padding: 10px;
}

.navbar-logo {
	width: 118px;
	height: 40px;
}

.navbar-default {
	background: #fff;
	margin: 0;
	border: 0;
}

.navbar-default .navbar-nav>li>a {
	color: #333;
	padding: 20px 12px 15px;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 5px solid #fff;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
	color: #09519d;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
	color: #111;
	background-color: #fff;
	border-color: #f18e00;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    background: none;
}

.dropdown-menu {
	padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

.dropdown-menu>li>a {
    padding: 8px 20px;
}

.single-referenser #menu-primar .menu-referenser a, .single-jobb #menu-primar .menu-jobba-hos-oss a  {
	color: #111;
	background-color: #fff;
	border-color: #f18e00;
}

.page-header {
	margin: 0;
	padding: 0;
	position: relative;
	border: 0;
}

.page-header-inner {
	background: #09519d;
	background: rgba(25,60,157,0.9);
}

.page-title {
	position: relative;
	padding: 50px 0 60px;
	text-align: center;
	z-index: 20;
}

.page-title h1 {
	margin: 0;
	padding: 0;
	font-size: 45px;
	color: #fff;
}

.page-header h1:after {
	content: "";
	display: block;
	background-color: #f18e00;
	margin: 10px auto 0 auto;
	width: 50px;
	height: 4px;
	line-height: 0;
}

.page-breadcrumb {
	background: #fff;
	position: relative;
	margin: -10px -40px 0 -40px;
}

@media (min-width: @grid-float-breakpoint) {
.page-breadcrumb:before, .page-breadcrumb:after {
	content: '';
	display: block;
	position: absolute;
	top: 10px;
	left: -15px;
	width: 15px;
	height: 100px;
}

.page-breadcrumb:before {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), to(rgba(0,0,0,0.15)));
	background-image: -webkit-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: -moz-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: -o-linear-gradient(left, rgba(255,255,255,0), rgba(0,0,0,0.15));
	background-image: linear-gradient(to right, rgba(255,255,255,0),rgba(0,0,0,0.15));
}

.page-breadcrumb:after {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.15)), to(rgba(255,255,255,0)));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: -o-linear-gradient(left, rgba(0,0,0,0.15), rgba(255,255,255,0));
	background-image: linear-gradient(to right, rgba(0,0,0,0.15),rgba(255,255,255,0));
	left: auto;
	right: -15px;
}
}

.page-breadcrumb-inner2 {
	padding: 15px 40px;
	color: dimgrey;
	background-color: #f9f9f9;
	border-bottom: 1px solid #f1f1f1;
}

@media (min-width: @grid-float-breakpoint) {
.page-breadcrumb-inner2:before, .page-breadcrumb-inner2:after {
	content: '';
	display: block;
	position: absolute;
	top: 10px;
	left: -15px;
	width: 15px;
	height: 100px;
}

.page-breadcrumb-inner2:before, .page-breadcrumb-inner2:after {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#fff));
	background-image: -webkit-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: -moz-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: -o-linear-gradient(top, rgba(255,255,255,0), #fff);
	background-image: linear-gradient(to bottom, rgba(255,255,255,0),#ffffff);
	z-index: 10;
}

.page-breadcrumb-inner2:after {
	left: auto;
	right: -15px;
}
}

#breadcrumbs {
	margin: 0;
}

#breadcrumbs span {
	margin: 0 6px;
}

#breadcrumbs span:first-child {
	margin-left: 0;
}

#breadcrumbs span:last-child {
	margin-right: 0;
}