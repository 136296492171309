.sidebar .widget h3 {
	padding-bottom: 12px;
	font-weight: bold;
	font-size: 1.125em;
	text-transform: uppercase;
	border-bottom: 1px solid #e2e2e2;
	margin-top: 0;
}

.sidebar .widget_nav_menu ul, .sidebar .widget_recent_entries ul, .sidebar .widget_categories ul, .sidebar .widget_archive ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sidebar .widget_nav_menu ul li, .sidebar .widget_recent_entries ul li, .sidebar .widget_categories ul li, .sidebar .widget_archive ul li {
	padding: 0;
}

.sidebar .widget_nav_menu ul li a, .sidebar .widget_recent_entries ul li a, .sidebar .widget_categories ul li a, .sidebar .widget_archive ul li a {
	display: block;
	background: #f9f9f9;
	padding: 10px 15px;
	margin-bottom: 3px;
	text-transform: capitalize;
}

.sidebar .widget_search .input-group {
	width: 100%;
}

.sidebar .search-submit {
	display: block;
	width: 100%;
	padding: 4px;
}

.sidebar .widget {
	margin-bottom: 20px;
}

.sidebar .widget:last-child {
	margin-bottom: 0;
}