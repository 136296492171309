@media (max-width: @grid-float-breakpoint) {
	.navbar-brand {
		padding-left: 15px;
	}
	.navbar-toggle {
		margin: 20px 15px;
		padding: 0;
		border: 0;
		border-radius: 0;
	}
	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
		background: none;
	}
	.navbar-default .navbar-toggle .icon-bar {
		background-color: #09519d;
	}
	.navbar-toggle .icon-bar {
		width: 25px;
		height: 4px;
		border-radius: 0;
	}
	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border-color: #e2e2e2;
	}
	#menu-primar {
		margin: 0 -15px;
	}
	.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
		color: #f18e00;
		border-color: #e2e2e2;
	}
	.navbar-default .navbar-nav>li>a {
		padding: 10px 15px;
		border-bottom: 1px solid #e2e2e2;
	}
	.single-referenser #menu-primar .menu-referenser a, .single-jobb #menu-primar .menu-jobba-hos-oss a {
		color: #f18e00;
		border-color: #e2e2e2;
	}
	.page-breadcrumb {
		margin: 0 -15px;
	}
	.page-breadcrumb-inner2 {
		padding: 15px;
	}
	.page-title-inner {
  		margin: 0;
	}
	.page-title-bar-inner {
		padding: 12px 0 15px 0;
	}
	.page-title-bar {
		padding: 0;
	}
	.page-title-bar-left {
		display: none;
	}
	.page-title-bar-right {
		float: none;
	}
	.page-title-bar-right .btn {
		display: block;
		width: 100%;
	}
	.page-title {
		padding: 30px 0 40px;
	}
	.page-title h1 {
		font-size: 36px;
	}
	#byggservice h2 {
		font-size: 26px;
		margin-top: 0;
	}
	.certificate-block {
		margin-bottom: 15px;
	}
	.certificate-images {
		padding-left: 5px;
	}
	.news-item {
		margin-bottom: 15px;
	}
	.news-item:last-child {
		margin-bottom: 0;
	}
	.grey-block {
  		padding: 15px;
	}
	.posts-navigation .nav-previous, .posts-navigation .nav-next {
		float: none;
	}
	.posts-navigation .nav-previous a, .posts-navigation .nav-next a {
		display: block;
	}
	.posts-navigation .nav-previous a {
		margin-bottom: 5px;
	}
	.sidebar {
		margin-top: 45px;
	}
	/* Footer */
	.footer-inner .copyright, .footer-inner .developer {
		text-align: center;
	}
	.footer-inner .copyright {
		margin-bottom: 10px;
	}
}

@media (min-width: @grid-float-breakpoint) {
	h2 {
		position: relative;
		margin-bottom: 20px;
		font-size: 2.25em;
		line-height: 1.2em;
		font-weight: normal;
	}
	#menu-primar {
		float: right;
	}
	.front-header-text h1 {
		font-size: 3em;
		line-height: 1.2em;
		font-weight: 400;
		margin: 0;
	}
	/* Footer */
	.footer-inner .copyright {
		float: left;
	}	
	.footer-inner .developer {
		float: right;
	}
}